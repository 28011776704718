<template>
    <div class="container1150">
        <header>
            <strong>市政道路：光明新区36和38号路及塘桥西路</strong>
        </header>
        <main>
            <div class="img1"></div>
            <h2>详细介绍</h2>
            <p>
                2009年我公司设计的光明新区36、38号路，是光明新区低影响开发示范区第一条市政道路，，被被建设部《海绵型城市建设指南》附录4中当作典型案例所引用。
            </p>
            <p>
                塘桥西路位于深圳市龙岗区坪地，是我公司承接的深圳国际低碳城启动项目的第一条低影响开发示范道路。该道路采用的低影响开发综合示范工程设施包括：透水沥青、排水路缘石、初期雨水处理、下沉绿地、透水人行道等。将路面雨水进行收集、过滤、入渗，能够有效地解决道路排水问题。
            </p>
            <div class="img2"></div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 500px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/news/news4-1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            // width: 100%;
            width: 900px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/news/news4-2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>